<template>
    <v-row class="fill-height">
        <v-col cols="12" class="mt-1 pl-6 pr-6" style="max-width: 760px">
            <!-- <div class="d-flex justify-center mb-12 mt-6">
                <img src="/svg/otc_logo.svg"
                    width="120px"
                />
            </div> -->

            <ValidationObserver ref="observer"
                                v-slot="{ invalid }"
                                class="mt-8"
            >

                <form class="form"
                    style="width: 100%"
                    action=""
                    @submit.prevent="addPay"
                >
                    <div>
                        <label class="login_label grey--text">
                            Email
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="Email"
                                            rules="required|email"
                                            mode="aggressive"
                        >
                            <v-text-field v-model.trim="add_pay.user_email"
                                        class="mt-1"
                                        outlined
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="addPay"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <div>
                        <label class="login_label grey--text">
                            입금자명
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="이름"
                                            rules="required"
                                            mode="aggressive"
                        >
                            <v-text-field v-model="add_pay.user_name"
                                        class="mt-1"
                                        outlined
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="addPay"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <div>
                        <label class="login_label grey--text">
                            휴대폰번호
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="phone"
                                            mode="aggressive"
                                            rules="required|min:11"
                        >
                            <v-text-field v-model="add_pay.phone"
                                        class="mt-1"
                                        outlined
                                        type="phone"
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="addPay"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <!-- <div class="mt-6">
                        <v-btn @click="req_loginWithUserEmail"
                            color="primary"
                            depressed
                            block
                            style="color:#ffffff"
                            :disabled="invalid"
                        >
                            로그인
                        </v-btn>
                    </div> -->

                     <v-radio-group v-model="radioGroup">
                        <v-radio
                            v-for="(row, i) in radio"
                            @change="changeRadio(row)"
                            :key="i"
                            :label="row.name"
                        >
                        </v-radio>

                        <v-text-field
                            v-if="radioGroup == 3"
                            v-model="etc_money"
                            style="width: 150px"
                            type="number"
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-radio-group>

                    <div class="d-flex">
                        <v-col cols="6">
                            <v-btn block color="grey" dark @click="backPage">
                                취소
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block color="primary" depressed  
                                @click="addPay" :disabled="invalid" style="color:white">
                                충전요청
                            </v-btn>
                        </v-col>
                    </div>
                </form>
            </ValidationObserver>
        </v-col>

        <v-col>
            <!-- <v-radio-group v-model="radioGroup">
                <v-radio
                    v-for="(row, i) in radio"
                    @change="changeRadio(row)"
                    :key="i"
                    :label="row.name"
                >
                </v-radio>
                <v-text-field
                    v-if="radioGroup == 3"
                    v-model="etc_money"
                    style="width: 150px"
                    type="number"
                    dense
                    outlined
                    hide-details
                ></v-text-field>
            </v-radio-group>
            <div class="d-flex">
                <v-col cols="6">
                    <v-btn block color="grey" dark @click="backPage">
                        취소
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" depressed dark @click="addPay">
                        결제
                    </v-btn>
                </v-col>
            </div> -->
        </v-col>

        <!-- *************************************************************************** -->
        <!-- SnackBar : Start  ********************************************************* -->
        <v-snackbar v-model="snackbar">
            {{ snackbar_msg }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <!-- SnackBar : End  ************************************************************** -->
        <!-- ****************************************************************************** -->
    </v-row>
</template>

<script>
import axios from "../../../service/axios";
import { required, email, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager');
extend('email', {
    ...email,
    message: 'Email 형식을 확인해주세요',
});
extend('required', {
    ...required,
    message: '{_field_} 입력해주세요',
});
// extend('phone', {
//     params: ['target'],
//     validate(value, { target }) {
//         return value === target;
//     },
//     message: '-제거'
// });
extend('min', {
    ...min,
    message: '- 를 제거 하고 입력해주세요',
});
export default {
    name: "AddMoney",
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            radioGroup: 0,
            radio: [
                {
                    name: "10,000원",
                    value: "10,000원",
                },
                {
                    name: "20,000원",
                    value: "20,000원",
                },
                {
                    name: "30,000원",
                    value: "30,000원",
                },
                {
                    name: "기타",
                    // value: null,
                },
            ],
            // radio: [
            //     "10,000원", "20,000원", "30,000원", "기타"
            // ],
            etc_money: null,
            add_pay: {
                user_email: null,
                user_name: null,
                phone: null,
                value: "10,000원"
            },
            snackbar: false,
            snackbar_msg: null,
        }; // return
    },
    created() {
        this.add_pay = this.radio[0];
        // this.$store.commit(
        //     {
        //         type: "setSubPage",
        //         sub_page: '충전금액',
        //     }
        // );
        // let test = this.$store.getters.getSubPage
        // console.log('Sub Page Name : ', test)
    },
    methods: {
        changeRadio(item) {
            console.log('item : ', item)
            if (this.radioGroup != 3) {
                this.etc_money = null;
            }
            this.add_pay.value = item.value;
        },
        backPage() {
            this.$router.go(-1);
        },
        addPay() {
            if (this.radioGroup == 3) {
                this.add_pay.value = this.etc_money;
            }
            console.log(this.add_pay);
            this.askCharge();
            // this.snackbar = true;
            // this.snackbar_msg = "준비중입니다";
        },

        askCharge() {
            // this.buy_dialog = false;
            // var product_name = `사이콘캐쉬 충전 - ${sale_price}`;
            let str = this.add_pay.value
            let cleanData = Number(str.replace(/,|원/g, ''))
            this.add_pay.value = cleanData
            
            const params = {
                accessKey: this.$cookies.get("token"),
                options: this.add_pay,
                type: "충전요청",
            };
            axios
                .post("/payment/createPaymentCharge", params)
                .then((res) => {
                    let serverCash = res.data.data.item
                    if(serverCash == 0) {
                        this.snackbar = true;
                        this.snackbar_msg = "충전요청이 완료됐습니다.";
                    } else {
                        this.$store.commit({ type: "setBalance", balance: serverCash });
                        // console.log('Res : ', res.data.data.item)
                        this.snackbar = true;
                        this.snackbar_msg = "충전요청이 완료됐습니다.";
                    }
                    // this.$router.go(-1)
                    this.$router.replace("/");



                    // var payment_id = res.data.data.payment_id;
                    // var user_name = res.data.data.user_name;
                    // var product_price = sale_price;
                    // var order_name = `${product_name}`;
                    // var order_id = payment_id;
                    // console.log('구매데이터', res.data);
                    // console.log(`주문번호 : ${order_id}, 주문명 : ${order_name}, 주문가격 : ${product_price}, 사용자명 : ${user_name}`)
                    // var clientKey = "test_ck_d26DlbXAaV021bYM5Db8qY50Q9RB";
                    // var tossPayments = window.TossPayments(clientKey);
                    // // console.log(tossPayments);
                    // tossPayments.requestPayment("카드", {
                    //     amount: product_price,
                    //     orderId: order_id,
                    //     orderName: order_name,
                    //     customerName: user_name,
                    //     successUrl: window.location.origin + "/charge/success",
                    //     failUrl: window.location.origin + "/fail",
                    // });
                })
                .catch((error) => {
                    console.log("페스트 오류", error);
                });
        },
    },
};
</script>

<style scoped>
</style>